import { useEffect, useRef, useState } from "react";
import css from "./Cryptograms.module.css";
import ConstantLetter from "./ConstantLetter";
import LetterInput from "./LetterInput";
// import { useAlert } from "react-alert";
import { createRandomFromSeed } from "./Random";
import Toolbar from "./Toolbar";
import Settings from "./Settings";
import HowToPlay from "./HowToPlay";

// TODO: maybe make it so you can't use already used letter

const Cryptograms = () => {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");
  const [onHardMode, setOnHardMode] = useState(false);
  const [userInputs, setUserInputs] = useState([""]);
  const [focus, setFocus] = useState<number | undefined>(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);

  const alphabet = useRef(Array.from(Array(26).keys()));

  // const alert = useAlert();

  useEffect(() => {
    // get random quote
    const day1 = new Date("Jan 13, 2024");
    const dayNow = new Date(new Date().toDateString());
    const daysApart = Math.round(
      (dayNow.valueOf() - day1.valueOf()) / (1000 * 60 * 60 * 24)
    );
    const batch = (daysApart - (daysApart % 20)) / 20 + 1;

    fetch(
      `http://api.quotable.io/quotes?page=${batch}&minLength=100&maxLength=400`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then((result) => {
      saveQuoteData(result, daysApart % 20);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInputs]);

  const saveQuoteData = async (response: any, index: number) => {
    setUserInputs([]);
    const allQuotes = JSON.parse(await response.text());
    const result = allQuotes.results[index];
    const author = result.author as string;
    const quote = result.content as string;
    shuffleAlphabet(quote.toUpperCase());
    setAuthor(author.toUpperCase());
    setQuote(quote.toUpperCase());
    setUserInputs(
      Array.from(Array(quote.length + author.length)).map((_) => "")
    );
  };

  const shuffleAlphabet = (seed: string) => {
    setRandomSeed(seed);
    alphabet.current = Array.from(Array(26).keys()); // Reset the alphabet to get consistent shuffle
    shuffleArray(alphabet.current, random); // Shuffle the array using our seeded random function
  };

  const shuffleArray = (array: any[], randomFn: () => number) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(randomFn() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  const onInputChanged = (
    encryptedLetter: string,
    inputLetter: string,
    index: number
  ) => {
    const inputCharacter = inputLetter
      .substring(inputLetter.length - 1)
      .toUpperCase();

    const updatedUserInputs = userInputs.map((char, elIndex) => {
      if (onHardMode) {
        return index === elIndex ? inputCharacter : char;
      } else {
        return encryptedLetterAtIndex(elIndex) === encryptedLetter
          ? inputCharacter
          : char;
      }
    });

    if (inputLetter.trim() !== "") {
      focusNextEmptyInput(index, encryptedLetter);
    } else if (userInputs[index] === "") {
      focusPrevInput(index);
    }

    setUserInputs(updatedUserInputs);
  };

  const focusPrevInput = (index: number) => {
    let prevIndex = index - 1;
    while (prevIndex >= 0) {
      const prevLetter = letterAtIndex(prevIndex);
      if (isLetter(prevLetter)) {
        setFocus(prevIndex);
        return;
      }
      prevIndex--;
    }
    setFocus(undefined);
  };

  const focusNextEmptyInput = (index: number, encryptedLetter: string) => {
    let nextIndex = index + 1;
    while (nextIndex < quote.length + author.length) {
      const nextLetter = letterAtIndex(nextIndex);
      const inputAtNextLetter = userInputs[nextIndex];
      const encryptAtNextLetter = encryptChar(quoteAndAuthorArray()[nextIndex]);
      if (
        isLetter(nextLetter) &&
        (inputAtNextLetter === undefined || inputAtNextLetter.trim() === "") &&
        encryptAtNextLetter !== encryptedLetter
      ) {
        setFocus(nextIndex);
        return;
      }
      nextIndex++;
    }
    setFocus(undefined);
  };

  const quoteAndAuthorArray = () => {
    return quote.split("").concat(author.split(""));
  };

  const letterAtIndex = (index: number) => {
    return quoteAndAuthorArray()[index];
  };

  const encryptedLetterAtIndex = (index: number) => {
    return encryptChar(letterAtIndex(index));
  };

  const isLetter = (c: string) => c.match(/[A-Z]/i) !== null;

  const chunckWords = (s: string) => {
    let words = [] as any[];
    let newWord = [] as any[];
    s.split("").forEach((c, i) => {
      newWord.push([c, i]);
      if (c === " ") {
        words.push(newWord);
        newWord = [];
      }
    });
    words.push(newWord);
    return words;
  };

  const encryptChar = (c: string) => String.fromCharCode(alphabet.current[c.charCodeAt(0) - 65] + 65);

  const getLetters = (s: string, indexOffset: number) =>
    chunckWords(s).map((wordData, wi) => (
      <div className={css.word} key={wi}>
        {wordData.map((data: any[]) => {
          const i = data[1] + indexOffset;
          const c = data[0];
          const encryptedLetter = encryptChar(c);

          return isLetter(c) ? (
            <LetterInput
              forceFocus={focus === i}
              highlighted={
                !onHardMode &&
                focus !== undefined &&
                encryptChar(letterAtIndex(focus)) === encryptedLetter
              }
              warning={
                !onHardMode &&
                focus !== undefined &&
                userInputs[i] !== undefined &&
                userInputs[i].trim() !== "" &&
                encryptChar(letterAtIndex(focus)) !== encryptedLetter &&
                userInputs[i] === userInputs[focus]
              }
              encryptedLetter={encryptedLetter}
              inputLetter={userInputs[i] ?? ""}
              onInputChanged={(letter) =>
                onInputChanged(encryptedLetter, letter, i)
              }
              key={`${i} ${encryptedLetter}`}
              onFocus={() => setFocus(i)}
              onBlur={() => setFocus(undefined)}
            />
          ) : (
            <ConstantLetter letter={c} key={i} />
          );
        })}
      </div>
    ));

  const checkAnswer = () => {
    const quoteArray = quoteAndAuthorArray();
    const allFilled = forAll(
      quoteArray,
      (el, i) => !isLetter(el) || !!userInputs[i]
    );
    if (allFilled && quoteArray.length > 0) {
      const answerResult = forAll(
        quoteArray,
        (el, i) => !isLetter(el) || userInputs[i] === el
      );
      if (answerResult) {
        alert(
          "Congrats!!! You solved the puzzle. Enjoy the quote's wisdom."
        );
      } else {
        alert("The puzzle isn't solved");
      }
    }
  };

  return isSettingsOpen ? (
    <Settings
      closeSettings={() => setIsSettingsOpen(false)}
      onHardMode={onHardMode}
      setOnHardMode={setOnHardMode}
    />
  ) : isHowToPlayOpen ? (
    <HowToPlay closeHowToPlay={() => setIsHowToPlayOpen(false)} />
  ) : (
    <div className={css.parent}>
      <Toolbar
        openSettings={() => setIsSettingsOpen(true)}
        openHowToPlay={() => setIsHowToPlayOpen(true)}
      />

      <div className={css.content}>
        <div className={css.quoteContainer}>{getLetters(quote, 0)}</div>
        <div className={css.authorContainer}>
          {getLetters(author, quote.length)}
        </div>
      </div>
    </div>
  );
};

function forAll<T>(list: T[], condition: (el: T, i: number) => boolean) {
  return list.map((el, i) => condition(el, i)).filter((b) => !b).length === 0;
}

let random = createRandomFromSeed("");

function setRandomSeed(s: string) {
  random = createRandomFromSeed(s);
}

export default Cryptograms;
