
import css from './AdventureBox.module.css';

export type ImageInfo = {
    imageUrl: string,
    caption?: string
}

type AdvenureBoxProps = {
    description: string,
    images: ImageInfo[]
}

const AdvenureBox = ({ description, images }: AdvenureBoxProps) => {

    return (
        <div className={css.box}>
            <p className={css.description}>{description}</p>
            <div className={css.imageGrid}>
                {images.map(imageInfo =>
                    <div key={imageInfo.imageUrl} className={css.imageBox + ' ' + (imageInfo.caption ? css.imageBoxCaption : css.imageBoxNoCaption)} >
                        <img src={imageInfo.imageUrl} alt='' className={css.image} />
                        {imageInfo.caption && <p className={css.caption}>{imageInfo.caption}</p>}
                    </div>)}
            </div>
        </div>
    )
}

export default AdvenureBox;
