import { MutableRefObject, useEffect, useRef } from "react";
import css from "./LetterInput.module.css";
import classnames from "classnames";

type LetterInputProps = {
  encryptedLetter: string;
  inputLetter: string;
  forceFocus: boolean;
  highlighted: boolean;
  warning: boolean;
  onInputChanged: (letter: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  editable?: boolean;
};

const moveCursorToEnd = (
  inputRef: MutableRefObject<HTMLInputElement | null>
) => {
  if (inputRef && inputRef.current) {
    inputRef.current.setSelectionRange(1, 1);
  }
};

const LetterInput = ({
  encryptedLetter,
  inputLetter,
  forceFocus,
  highlighted,
  warning,
  onInputChanged,
  onFocus,
  onBlur,
  editable = true,
}: LetterInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && forceFocus) {
      (inputRef.current as HTMLInputElement).focus();
    }
  }, [forceFocus]);

  return (
    <div className={css.letterContainer}>
      <input
        ref={inputRef}
        className={classnames(css.input, {
          [css.inputFilled]: inputLetter,
        })}
        value={inputLetter}
        onChange={(e) => {
          if (e.target.value !== "") {
            onInputChanged(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          moveCursorToEnd(inputRef);
          if (e.key === "Backspace" || e.key === "Delete") {
            onInputChanged("");
          }
        }}
        onMouseUp={() => {
          moveCursorToEnd(inputRef);
        }}
        onFocus={() => {
          setTimeout(() => {
            moveCursorToEnd(inputRef);
          }, 0);
          onFocus();
        }}
        onBlur={onBlur}
        disabled={!editable}
      />
      <p
        className={classnames(css.letter, {
          [css.highlighted]: highlighted,
          [css.warning]: warning,
        })}
      >
        {encryptedLetter}
      </p>
    </div>
  );
};

export default LetterInput;
