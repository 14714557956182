
import css from './ProjectBox.module.css';

import right_arrow from '../../images/right_arrow.png';

type ProjectBoxProps = {
    title: string,
    role?: string,
    description: string,
    imgUrl: string,
    link?: string,
    children?: JSX.Element[]
}

const ProjectBox = ({ title, role = 'Founder', description, imgUrl, link, children }: ProjectBoxProps) => {

    return (
        <div className={css.box}  >
            <img src={imgUrl} alt={title} className={css.webImg} />
            <div className={css.content}>
                <p className={css.subtitle}>{role}</p>
                <p className={css.title}>{title}</p>
                <img src={imgUrl} alt={title} width='100%' className={css.mobileImg} />
                <p className={css.description}>{description}</p>
                {children && children}
                {link &&
                    <a className={css.visitBox} href={link} target="_blank" rel="noreferrer">
                        <p className={css.visitText}>Visit site</p>
                        <img src={right_arrow} alt='arrow' width='52' />
                    </a>
                }
            </div>
        </div>
    )
}

export default ProjectBox;
