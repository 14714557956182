import { useEffect, useState } from "react";
import OpenAI from "openai";
import Recipe from "./Recipe";
import ShoppingList from "./ShoppingList";
import CreateMealsForm, { createMeals } from "./CreateMealsForm";
import MealPreview from "./MealPreview";
import { DietType, Preferences } from "./CreateMealsForm";

export type Meal = {
  title: string;
  description: string;
  time: string;
  calories: number;
  protein: number;
  carbohydrates: number;
  fat: number;
  recipe: string[];
  ingredients: string[];
  image: string;
  servings: number;
};

interface IngredientCategory {
  name: string;
  items: string[];
}

const MealPrep = () => {
  const [preferences, setPreferences] = useState<Preferences>({
    dietType: DietType.Anything,
    dislikedFoods: "",
    cravings: "",
  });
  const [meals, setMeals] = useState<Meal[]>([]);
  const [selectedMeal, setSelectedMeal] = useState<Meal | null>(null);

  const ingredientCategories = [
    "Produce",
    "Protein",
    "Dairy",
    "Grains",
    "Spices",
    "Pantry",
  ];
  const [allIngredients, setAllIngredients] = useState<IngredientCategory[]>(
    []
  );

  const getIngredients = async (meals: Meal[]) => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      // TODO: this exposes my api key, make backend route to call openai
      dangerouslyAllowBrowser: true,
    });

    // Get all ingredients from meals
    const allMealIngredients = meals.flatMap((meal: Meal) => meal.ingredients);

    console.log("allMealIngredients", meals, allMealIngredients);

    // Make second OpenAI call to combine and categorize ingredients
    const ingredientsResponse = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
        {
          role: "system",
          content: `You are a helpful assistant that organizes shopping lists. Combine similar ingredients and their quantities, and categorize them by type.
      Convert cup/volume measurements to whole units where possible (e.g. "2 bell peppers" instead of "1 cup diced bell peppers").
      For produce and packaged items, list the number of items needed rather than cup measurements.
      Exclude common pantry items that users likely have (salt, pepper, water, olive oil, etc.).
      Do not include preparation instructions like "diced", "chopped", or "halved" since this is just for shopping.
      Don't put vegan items (like beans, chickpeas, tofu, etc.) in the protein category. It should be in the pantry category.
      The ingredients must be categorized into these specific categories: ${ingredientCategories.join(
        ", "
      )}.
      Return a JSON object with this structure:
      {
        "categories": [
          {
            "name": "Produce", 
            "items": ["2 large onions", "3 bell peppers"]
          },
          {
            "name": "Grains",
            "items": ["2 cups brown rice", "1 pack tortillas"]
          }
        ]
      }`,
        },
        {
          role: "user",
          content: `Please organize these ingredients into a categorized shopping list, combining similar ingredients and their quantities. Convert cup measurements to whole units where possible: ${JSON.stringify(
            allMealIngredients
          )}`,
        },
      ],
      response_format: { type: "json_object" },
    });

    const categorizedIngredients = JSON.parse(
      ingredientsResponse.choices[0].message.content ?? ""
    );

    console.log("categorizedIngredients", categorizedIngredients);

    setAllIngredients(categorizedIngredients.categories);
  };

  useEffect(() => {
    if (!allIngredients.length && meals.length > 0) {
      getIngredients(meals);
    }
  }, [meals]);

  const replaceMeal = async (meal: Meal, index: number) => {
    const newMeal = await createMeals(
      1,
      meal.servings,
      {
        cravings: "",
        dietType: preferences.dietType,
        dislikedFoods: preferences.dislikedFoods,
      },
      meals.filter((m) => m !== meal)
    );
    const newMeals = meals.map((m, i) => (i === index ? newMeal[0] : m));
    setMeals(newMeals);
    getIngredients(newMeals);
  };

  return (
    <div className="mx-auto mt-12 p-6 font-work-sans">
      <h1 className="text-5xl font-lora font-semibold leading-tight text-brown mb-8 text-center">
        Meal Planner
      </h1>

      {selectedMeal ? (
        <Recipe meal={selectedMeal} goBack={() => setSelectedMeal(null)} />
      ) : meals.length > 0 ? (
        <div className="flex flex-col sm:flex-row gap-8 justify-center">
          <ShoppingList allIngredients={allIngredients} />

          <div className="flex flex-col gap-8 max-w-7xl">
            {meals.map((meal, index) => (
              <MealPreview
                meal={meal}
                openMeal={setSelectedMeal}
                replaceMeal={(m) => replaceMeal(m, index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <CreateMealsForm
          onCreate={(m, p) => {
            setMeals(m);
            setPreferences(p);
          }}
        />
      )}
    </div>
  );
};

export default MealPrep;
