import css from "./Settings.module.css";
import css2 from "./Cryptograms.module.css";

import close from "./close.png";
import LetterInput from "./LetterInput";

type HowToPlayProps = {
  closeHowToPlay: () => void;
};

const HowToPlay = ({ closeHowToPlay }: HowToPlayProps) => {
  const word = (letters: string[], answers?: string[]) => (
    <div className={css2.word} style={{ marginRight: "40px" }}>
      {letters.map((letter, i) => {
        return (
          <LetterInput
            forceFocus={false}
            highlighted={false}
            warning={false}
            encryptedLetter={letter}
            inputLetter={answers ? answers[i] : ""}
            onInputChanged={() => {}}
            key={letter}
            onFocus={() => {}}
            onBlur={() => {}}
            editable={false}
          />
        );
      })}
    </div>
  );

  return (
    <div className={css.parent}>
      <div className={css.headerBox}>
        <p className={css.header} style={{ marginBottom: "30px" }}>
          HOW TO PLAY
        </p>
        <img
          src={close}
          alt="close"
          width={30}
          height={30}
          onClick={closeHowToPlay}
          className={css.closeIcon}
        />
      </div>
      <p className={css.text}>
        Every letter in the alphabet has been replaced with another letter!
      </p>
      <p className={css.text}>
        UnSCRAMBLE the quote by figuring out which letter is which
      </p>
      <div className={css.line} />
      <p className={css.text}>Example</p>
      <p className={css.text}>The scrmabled text here:</p>
      <div style={{ display: "flex" }}>
        {word(["A", "S", "Y", "Y", "M"])}
        {word(["O", "A", "S", "P", "S"])}
      </div>
      <p className={css.text}>Can be unscrambled to:</p>
      <div style={{ display: "flex" }}>
        {word(["A", "S", "Y", "Y", "M"], ["H", "E", "L", "L", "O"])}
        {word(["O", "A", "S", "P", "S"], ["T", "H", "E", "R", "E"])}
      </div>
    </div>
  );
};

export default HowToPlay;
