import { FC, useState } from "react";
import { Meal } from "./MealPrep";

interface MealPreviewProps {
  meal: Meal;
  openMeal: (meal: Meal) => void;
  replaceMeal: (meal: Meal) => Promise<void>;
}

const Recipe: FC<MealPreviewProps> = ({ meal, openMeal, replaceMeal }) => {
  const [isReplacing, setIsReplacing] = useState(false);

  const createNewMeal = async () => {
    setIsReplacing(true);
    await replaceMeal(meal);
    setIsReplacing(false);
  }

  return (
    <div
      key={meal.title}
      className="relative rounded-lg border border-gray-200 shadow-lg flex flex-col sm:flex-row sm:h-[400px] md:h-[340px] lg:h-[270px]"
    >
      <img
        src={meal.image}
        alt={meal.title}
        className="w-full h-[200px] sm:w-2/5 sm:h-full object-cover rounded-t-lg sm:rounded-l-lg sm:rounded-t-none"
      />
      <div className="flex-1 flex flex-col gap-1 py-6 px-8">
        <h2 className="text-3xl font-medium text-[#5C2C0C]">{meal.title}</h2>
        <p className="text-lg text-gray-500">
          {meal.time}, {meal.calories} calories
        </p>
        <p className="text-xl mt-1 flex-1">{meal.description}</p>

        <button
          onClick={createNewMeal}
          disabled={isReplacing}
          className="text-gray-600 mt-8 text-[14px] rounded-md hover:underline disabled:opacity-50 disabled:hover:no-underline"
        >
          {isReplacing ? "Replacing..." : "Replace Meal"}
        </button>
        <button
          onClick={() => openMeal(meal)}
          disabled={isReplacing}
          className="mt-1.5 bg-[#5C2C0C] text-white py-2.5 px-4 rounded-md hover:bg-[#5C2C0C]/90 transition-colors font-medium w-full disabled:opacity-50 disabled:hover:bg-[#5C2C0C]"
        >
          Cook
        </button>
      </div>
    </div>
  )
};

export default Recipe;
