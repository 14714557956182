import { FC, useState, useEffect } from "react";
import { useScreen } from "../../hooks/useScreen";

interface IngredientCategory {
  name: string;
  items: string[];
}

interface ShoppingListProps {
  allIngredients: IngredientCategory[];
}

const ShoppingList: FC<ShoppingListProps> = ({ allIngredients }) => {
  const { isMobile } = useScreen();

  const [isExpanded, setIsExpanded] = useState(!isMobile);

  useEffect(() => {
    setIsExpanded(!isMobile);
  }, [isMobile]);

  return (
    <div className="bg-tan/40 p-6 rounded-lg border border-tan/60 min-w-[200px] md:min-w-[250px] lg:min-w-[300px]">
      <div className="flex justify-between items-center cursor-pointer sm:cursor-default" onClick={() => isMobile && setIsExpanded(!isExpanded)}>
        <h3 className="text-4xl font-medium text-gray-900">Shopping List</h3>
        <p className="text-4xl text-gray-600 sm:hidden">
          {isExpanded ? "-" : "+"}
        </p>
      </div>

      {isExpanded ? (!allIngredients || allIngredients.length === 0 ? (
        <div className="flex justify-center items-center gap-3 py-8 mt-4">
          <p className="text-xl">Loading your shopping list... </p>
        </div>
      ) : (
        <div className="mt-4">
          {allIngredients.map((category, index) => (
            category.items.length > 0 && (
            <div key={index} className="mb-10">
              <h4 className="text-3xl bg-brown/10 p-3 rounded-xl w-auto   ">
                {category.name === "Produce" && "🥬"}
                {category.name === "Protein" && "🥩"}
                {category.name === "Dairy" && "🥛"}
                {category.name === "Pantry" && "🥫"}
                {category.name === "Grains" && "🌾"}
                {category.name === "Spices" && "🌶️"} 
                {' ' +category.name}
              </h4>
              <ul className="space-y-3 mt-3">
                {category.items.map((ingredient: string, itemIndex: number) => (
                  <li
                    key={itemIndex}
                    className="flex items-center gap-2 ml-3 text-xl"
                  >
                    <input 
                      type="checkbox"
                      className="w-5 h-5 cursor-pointer rounded border-2 border-brown/20 checked:bg-brown checked:border-brown focus:ring-0 focus:ring-offset-0"
                    />
                    <span className="hover:text-brown transition-colors">
                      {ingredient}
                    </span>
                  </li>
                ))}
              </ul>
              </div>
            )
          ))}
        </div>
      )) : null}
    </div>
  );
};

export default ShoppingList;
