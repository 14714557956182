import css from "./LetterInput.module.css";

type ConstantLetterProps = {
  letter: string;
};

const ConstantLetter = ({ letter }: ConstantLetterProps) => (
  <div
    className={css.letterContainer}
    style={{ minWidth: letter === " " ? "28px" : "10px" }}
  >
    {letter === "\n" && <hr style={{ width: "100%" }} />}
    <p style={{ margin: "0 0 2px 0", height: "100%" }}>{letter}</p>
    <p className={css.letter}>{letter}</p>
  </div>
);

export default ConstantLetter;
