import { FC, useState } from "react";
import OpenAI from "openai";
import axios from "axios";
import { Meal } from "./MealPrep";

interface CreateMealsFormProps {
  onCreate: (meals: Meal[], preferences: Preferences) => void;
}

export enum DietType {
  Anything = "anything",
  Vegetarian = "vegetarian",
  Vegan = "vegan",
  Keto = "keto",
  Paleo = "paleo",
  GlutenFree = "gluten-free",
}

export interface Preferences {
  dietType: DietType;
  dislikedFoods: string;
  cravings: string;
}

const fetchMealImage = async (mealTitle: string): Promise<string | null> => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const searchEngineId = "c12f35af01ed2405e";
  const query = encodeURIComponent(mealTitle);
  const url = `https://www.googleapis.com/customsearch/v1?q=${query}&cx=${searchEngineId}&searchType=image&key=${apiKey}`;

  try {
    const { data } = await axios.get(url);
    return data.items[0]?.link || null;
  } catch (error) {
    console.error("Error fetching meal image:", error);
    return null;
  }
};

export const createMeals = async (
  numMeals: number,
  numServings: number,
  preferences: Preferences,
  otherMeals?: Meal[]
) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    // TODO: this exposes my api key, make backend route to call openai
    dangerouslyAllowBrowser: true,
  });

  const response = await openai.chat.completions.create({
    model: "gpt-4o-mini",
    messages: [
      {
        role: "system",
        content: `You are an expert meal planning assistant. Focus on popular and flavorful meals. Use HelloFresh as inspiration.
          The meals should have a variety of cuisines, focusing on good unique recipes. 
          The meals should be easy to cook but also packed with great flavors and seasonings.
          You will return a JSON response containing an array of meals. Don't assume the user has any cooked meat or grains etc. Do instructions from raw ingredients. 
          Make sure every meal is a full nutritious meal, not just a side or a snack. If there are sides, also include instructions for those.
          Each meal should have a title, description, recipe steps, list of ingredients with specific quantities,  
          time to cook, calories, grams of protein, grams of carbohydrates, and grams of fat. All nutritional info is per serving.
          The meals should be ${preferences.dietType}. 
          ${
            preferences.dislikedFoods
              ? `Also avoid these ingredients: ${preferences.dislikedFoods}.`
              : ""
          }
          Descriptions should detail all main components and flavors in the meal.
          Ingredient quantities should be precise (e.g. "2 cups brown rice" not just "brown rice").
          Your response must be valid JSON.`,
      },
      {
        role: "user",
        content: `Create ${numMeals} different meals with ${numServings} servings each. 
          ${preferences.cravings ? `The user has listed these cravings: ${preferences.cravings}. Make sure to include one separate meal for each craving if possible, and don't make all meals the same.` : ""}
          ${otherMeals?.length ? `The user is already cooking ${otherMeals?.map(m => m.title).join(", ")}. Make sure these meals are different from the ones they're already cooking.` : ""}
          Return the response as a JSON object with this structure:
          {
            "meals": [
              {
                "title": "string",
                "description": "A detailed description listing the main components and flavors",
                "recipe": ["Preheat oven to 350F", "Mix ingredients in bowl", etc],
                "ingredients": ["2 cups ingredient", "1 tbsp sauce", etc],
                "time": "string", 
                "calories": "number",
                "protein": "number", 
                "carbohydrates": "number",
                "fat": "number",
              }
            ]
          }`,
      },
    ],
    response_format: { type: "json_object" },
  });
  const responseData = JSON.parse(response.choices[0].message.content ?? "");

  const mealsWithImages = await Promise.all(
    responseData.meals.map(async (meal: Meal) => {
      const image = await fetchMealImage(meal.title);
      return {
        ...meal,
        calories: Number(meal.calories),
        protein: Number(meal.protein),
        carbohydrates: Number(meal.carbohydrates),
        fat: Number(meal.fat),
        image: image || "",
        servings: numServings,
      };
    })
  );

  return mealsWithImages as Meal[];
};

const CreateMealsForm: FC<CreateMealsFormProps> = ({ onCreate }) => {
  const [numMeals, setNumMeals] = useState(3);
  const [numServings, setNumServings] = useState(1);

  const [preferences, setPreferences] = useState<Preferences>({
    dietType: DietType.Anything,
    dislikedFoods: "",
    cravings: ""
  });
  
  const [isLoading, setIsLoading] = useState(false);

  // Daily Totals: 1,808 calories, 101g fat, 23g saturated fat, 102g protein, 125g carbohydrate, 29g fiber, 2,253 mg sodium
  // Macronutrients: 192 calories, 7 grams protein, 31 grams carbohydrates, 5 grams fat

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const meals = await createMeals(
        numMeals,
        numServings,
        preferences
      );
      onCreate(meals, preferences);
    } catch (error) {
      console.error("Error generating meal plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-6 bg-tan/30 py-8 px-16 rounded-lg border-2 border-tan/40 max-w-6xl mx-auto">
      <div className="flex flex-col">
        <label className="text-brown font-medium mb-2">Diet Type</label>
        <select
          value={preferences.dietType}
          onChange={(e) => setPreferences({ ...preferences, dietType: e.target.value as DietType })}
          className="p-3 border-2 border-brown/20 rounded-md focus:outline-none focus:border-brown/40 transition-colors"
        >
          {Object.values(DietType).map((type) => (
            <option key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col">
        <label className="text-brown font-medium mb-2">Number of Meals</label>
        <input
          type="number"
          min="1"
          value={numMeals}
          onChange={(e) => setNumMeals(parseInt(e.target.value))}
          className="p-3 border-2 border-brown/20 rounded-md focus:outline-none focus:border-brown/40 transition-colors"
        />
      </div>

      <div className="flex flex-col">
        <label className="text-brown font-medium mb-2">Servings per Meal</label>
        <input
          type="number"
          min="1"
          value={numServings}
          onChange={(e) => setNumServings(parseInt(e.target.value))}
          className="p-3 border-2 border-brown/20 rounded-md focus:outline-none focus:border-brown/40 transition-colors"
        />
      </div>

      <div className="flex flex-col">
        <label className="text-brown font-medium mb-2">
          Foods You Don't Like
        </label>
        <input
          type="text"
          value={preferences.dislikedFoods}
          onChange={(e) => setPreferences({ ...preferences, dislikedFoods: e.target.value })}
          placeholder="e.g. mushrooms, beef, cilantro"
          className="p-3 border-2 border-brown/20 rounded-md focus:outline-none focus:border-brown/40 transition-colors"
        />
      </div>

      <div className="flex flex-col">
        <label className="text-brown font-medium mb-2">
          Anything in particular you're craving?
        </label>
        <input
          type="text"
          value={preferences.cravings}
          onChange={(e) => setPreferences({ ...preferences, cravings: e.target.value })}
          placeholder="e.g. pasta, curry, tacos"
          className="p-3 border-2 border-brown/20 rounded-md focus:outline-none focus:border-brown/40 transition-colors"
        />
      </div>

      <button
        onClick={handleGenerate}
        disabled={isLoading}
        className="mt-4 bg-[#5C2C0C] text-white py-3 px-6 rounded-md hover:bg-brown/90 transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? "Generating..." : "Generate Meal Plan"}
      </button>
    </div>
  );
};

export default CreateMealsForm;
