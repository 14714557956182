import { FC } from "react";
import { Meal } from "./MealPrep";
import { useScreen } from "../../hooks/useScreen";

interface RecipeProps {
  meal: Meal;
  goBack: () => void;
}

const Recipe: FC<RecipeProps> = ({ meal, goBack }) => {
  const { isMobile } = useScreen();

  return (
    <div className="max-w-6xl m-auto">
      <button onClick={goBack} className="text-brown/80 mb-4 hover:underline">
        {`< Back`}
      </button>

      <div className="border-2 border-gray-200 rounded-lg mb-20 shadow-lg">
        <img
          src={meal.image}
          alt={meal.title}
          className="w-full h-[300px] object-cover rounded-t-md"
        />
        <div className="py-10 px-9 sm:px-14 text-xl">
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 text-xl text-brown/70">
            <p>Cook time: {meal.time}</p>
            {!isMobile && <p>•</p>}
            <p>Servings: {meal.servings}</p>
          </div>

          <h2 className="text-5xl leading-normal font-lora text-[#5C2C0C] font-semibold mb-4">
            {meal.title}
          </h2>

          <p className="leading-relaxed mb-2 text-brown/90">
            {meal.description}
          </p>

          <h3 className="text-2xl text-brown font-medium mb-3 mt-8">
            Ingredients
          </h3>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            {meal.ingredients.map((ingredient, index) => (
              <li key={index}>{ingredient}</li>
            ))}
          </ul>

          <h3 className="text-2xl text-brown font-medium mb-3 mt-8">
            Instructions
          </h3>
          <ol className="list-decimal leading-relaxed pl-5 space-y-3 text-gray-700">
            {meal.recipe.map((step, index) => (
              <li key={index} className="">
                {step}
              </li>
            ))}
          </ol>

          <h3 className="text-2xl text-brown font-medium mt-10">
            Nutrition Facts per serving
          </h3> 
          <p className="text-lg text-gray-500">
              These are approximate values and should only be used as estimates
            </p>
          <div className="bg-gray-100/70 rounded-lg p-6 max-w-lg mt-3">
            <table className="w-full text-gray-700">
              <tbody>
                <tr className="border-b border-gray-200">
                  <td className="py-2 font-medium">Calories</td>
                  <td className="py-2 text-right">{meal.calories} calories</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="py-2 font-medium">Protein</td>
                  <td className="py-2 text-right">{meal.protein} grams</td>
                </tr>
                <tr className="border-b border-gray-200">
                  <td className="py-2 font-medium">Carbohydrates</td>
                  <td className="py-2 text-right">{meal.carbohydrates} grams</td>
                </tr>
                <tr>
                  <td className="py-2 font-medium">Fat</td>
                  <td className="py-2 text-right">{meal.fat} grams</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recipe;
