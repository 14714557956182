import css from "./Settings.module.css";

import close from "./close.png";
import classnames from "classnames";

type ToggleProps = {
  isOn: boolean;
  onToggle: (newIsOn: boolean) => void;
};

const Toggle = ({ isOn, onToggle }: ToggleProps) => (
  <div
    className={classnames(css.toggleBox, { [css.toggleBoxOn]: isOn })}
    onClick={() => {
      onToggle(!isOn);
    }}
  >
    <div
      className={classnames(css.toggleCircle, { [css.toggleCircleOn]: isOn })}
    />
  </div>
);

type SettingsProps = {
  closeSettings: () => void;
  onHardMode: boolean;
  setOnHardMode: (hardMode: boolean) => void;
};

const Settings = ({
  closeSettings,
  onHardMode,
  setOnHardMode,
}: SettingsProps) => {
  return (
    <div className={css.parent}>
      <div className={css.headerBox}>
        <p className={css.header}>SETTINGS</p>
        <img
          src={close}
          alt="close"
          width={30}
          height={30}
          onClick={closeSettings}
          className={css.closeIcon}
        />
      </div>
      <div className={css.setting}>
        <div>
          <p className={css.settingName}>Hard Mode</p>
          <p className={css.settingText}>
            When you fill in one letter, the others are not automatically filled
            it
          </p>
        </div>
        <Toggle isOn={onHardMode} onToggle={setOnHardMode} />
      </div>
    </div>
  );
};

export default Settings;
