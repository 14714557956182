import css from "./Toolbar.module.css";

import gear from "./gear.png";
import question from "./question.png";

type ToolbarProps = {
  openSettings: () => void;
  openHowToPlay: () => void;
};

const Toolbar = ({ openSettings, openHowToPlay }: ToolbarProps) => {
  return (
    <div className={css.toolbar}>
      <img
        src={question}
        className={css.icon}
        alt="question"
        onClick={openHowToPlay}
      />
      <p className={css.header}>SCRAMBLE</p>
      <img
        src={gear}
        className={css.icon}
        alt="settings"
        onClick={openSettings}
      />
    </div>
  );
};

export default Toolbar;
